@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url("./fonts/avenir-medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"),
    url("./fonts/avenir-black.ttf") format("truetype");
  font-weight: normal;
}

html {
  background-color: #F1F1F1;
  scroll-behavior: smooth !important;
}

body {
  background-color: #F1F1F1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.medium {
  font-family: Avenir !important;
}

.black {
  font-family: AvenirBlack !important;
}

.gradient {
  height: 700px;
  width: 100%;
  position: relative;
  /* background: linear-gradient(0deg, rgba(193,0,236,1) 30%, rgba(120,1,218,1) 60%, rgba(79,0,205,1) 80%, rgba(49,0,195,1) 100%); */
  background: url(./images/background-main.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient-form {
  height: 700px;
  width: 100%;
  position: relative;
  /* background: linear-gradient(0deg, rgba(193,0,236,1) 30%, rgba(120,1,218,1) 60%, rgba(79,0,205,1) 80%, rgba(49,0,195,1) 100%); */
  background: url(./images/background-bottom.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-title {
  color: #FFFFFF;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.title {
  width: 100%;
}

.title img {
  width: 120px;
  height: auto;
}

.container-form {
  color: #FFFFFF;
  width: 100%;
}

.form {
  background-color: #FFFFFF;
  margin-left: 200px;
  margin-right: 200px;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.49);
  -webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.49);
  /* border-radius: 5px; */
}

.form form {
  padding: 10px;
}

.form label {
  color: #000000;
  font-size: 14px;
  padding-left: 5px;
}

.form input {
  background-color: #D5D5D5;
  border-radius: unset;
}

.form-title {
  width: 100%;
  height: auto;
  color: #FFFFFF;
  text-align: center;
  padding: 30px;
}

.form-logo {
  width: 100%;
  height: auto;
  text-align: center;
}

.form-logo img {
  width: 120px;
  height: auto;
}

.form-group {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
}

.form button {
  margin-top: 20px;
  margin-right: 20px;
  width: 100px;
  background-color: rgba(193,0,236,1);
  border: unset;
}

.form-button {
  text-align: right;
}

.calculator {
  width: 100%;
  height: auto;
  text-align: center;
}

.calculator h3 {
  margin: auto;
  color: #FFFFFF;
  width: 360px;
  height: 40px;
  padding: 3px;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(193,0,236,1) 30%, rgba(120,1,218,1) 60%, rgba(79,0,205,1) 80%, rgba(49,0,195,1) 100%);
}

.calculator-title {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
}

.slider {
  width: 340px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.calculator-background {
  width: 100%;
  height: auto;
  background: url(./images/logo-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.invest-button {
  background-color: rgba(193,0,236,1);
  color: #FFFFFF;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form {
    margin-left: 20px;
    margin-right: 20px;
  }

  .gradient {
    height: 700px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .form {
    margin-left: 20px;
    margin-right: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .form {
    margin-left: 120px;
    margin-right: 120px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .form {
    margin-left: 160px;
    margin-right: 160px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .form {
    margin-left: 400px;
    margin-right: 400px;
  }
}
